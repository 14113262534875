.table_margins {
    margin-bottom: 1.5rem;
}

.sprint_row {
    display: flex;
    align-items: top;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 0;
}

.sprint_box {
    width: 100%;
    height: 120px;
    background-color: #555;
    margin-top: 0.5rem;
    box-shadow: 0 0 40px 20px inset rgba(0, 0, 0, 0.13);
}

.segmented_col {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
}

.sprint_col {
    width: 0;
    height: 100%;
    flex: 1;
    margin-left: 0.2rem;
}

.sprint_col_left_edge {
    width: 0;
    height: 100%;
    flex: 1;
    margin-left: 0;
}

.divider_class.divider_class{
    width: 100%;
    background-color: #777;
    margin: 1rem 0;
}

.center_col {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.col_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 25px;
    line-height: 50px;
    width: 100%;
    padding: 0 0.8rem;
    background-color: #181818;
    color: white;
}

.col_name_sml {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
    line-height: 50px;
    width: 100%;
    padding: 0 0.3rem;
    background-color: #181818;
    color: white;
}

.warning_border {
    border: 3px solid #ff1f5f;
}

.empty_border {
    border: 3px solid transparent;
}

.display_max {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 100%;
    font-size: 30px;
    color: #777;
}
.no_tickets_text_lrg {
    font-size: 50px;
}

.no_tickets_text_sml {
    font-size: 30px;
}

.img_lrg {
    width: 600px;
    height: 450px;
    opacity: 0.5;
 }

 .img_sml {
    width: 350px;
    height: 280px;
    opacity: 0.5;
 }

 .no_tickets_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0;
 }
.img_lrg {
    width: 600px;
    height: 450px;
    opacity: 0.5;
 }

 .img_sml {
    width: 350px;
    height: 280px;
    opacity: 0.5;
 }

 .icon_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 }

 .text_lrg {
   font-size: 50px;
}

.text_sml {
   font-size: 30px;
}
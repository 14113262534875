body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-select-selector {
  background-color: #555 !important;
  border: none !important;
  border-radius: 0px !important;
  color: white !important;
}

.ant-select-item {
  color: white !important;
}

.ant-select-arrow {
  color: white !important;
}

.ant-select-item-option-active {
  background-color: #777 !important;
  color: white !important;
}

.ant-empty-description {
  color: white !important;
}

.ant-select-item-option-selected {
  background-color: #888 !important;
  color: white !important;
}

.ant-select-selection-item-content {
  color: black !important;
}

.ant-select-multiple .ant-select-selection-item {
  background: rgb(37, 255, 204) !important;
}

.ant-select-item-option-state {
  color: rgb(37, 255, 204) !important;
}

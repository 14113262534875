.dialog_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.inner_dialog_container {
    position: relative;
    padding: 32px;
    width: 90%;
    max-width: 800px;
    height: auto;
    max-height: 90%;
    background-color: #333;
    border-radius: 10px;
    overflow: auto;
}

.inner_lrg_padding {
    padding: 32px;
    padding-bottom: 2rem;
}

.inner_sml_padding {
    padding: 16px;
    padding-bottom: 2rem;
}

.inner_dialog_container .sml {
    position: relative;
    width: 90%;
    max-width: 800px;
    height: 90%;
    max-height: 800px;
    background-color: #333;
    border-radius: 10px;
    overflow: auto;
    padding: 32px 16px;
}

.inner_dialog_container::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.inner_dialog_container::-webkit-scrollbar-thumb {
    background-color: #b4b4b4; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners of the thumb */
}

.inner_dialog_container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the thumb on hover */
}

.inner_dialog_container::-webkit-scrollbar-track {
    background-color: #222; /* Color of the track */
}

.inner_dialog_container .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    background-color: red;
}

.header_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
}

.long_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 35px;
    line-height: 47px;
}

.divider_class.divider_class{
    width: 100%;
    background-color: #777;
    margin: 1rem 0;
}

.button_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;
}

.button_container {
    padding: 0;
    margin: 0;
}
.page_content {
    margin: 1rem 0;
    padding: 0rem 4rem;
    max-width: 2500px;
}

.page_content_sml {
    margin: 1rem 0;
    padding: 0rem 1rem;
}

.title_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 2rem 0;
    gap: 3rem;
}

.long_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 35px;
    line-height: 47px;
}

.sprint_box {
    width: 100%;
    height: 80px;
    background-color: #555;
    margin-bottom: 0.5rem;
}

.sprint_box_impeded {
    width: 170px;
    height: 80px;
    background-color: #555;
    margin-bottom: 0.5rem;
}

.sprint_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    gap: 1rem;
}

.create_ticket_row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.next_sprint_row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 3rem;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10rem;
    padding: 0;
}
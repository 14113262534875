.dropdown_menu {
    position: absolute;
    background-color: #c9c9c9;
    border-radius: 10px;
    right: 0;
    padding: 20px;
    width: 250px;
    color: black;
    transform: translateY(55px);
    overflow: auto;
    height: auto;
    max-height: 400px;
    z-index: 1;
}

.dropdown_menu::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.dropdown_menu::-webkit-scrollbar-thumb {
    background-color: #b4b4b4; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners of the thumb */
}

.dropdown_menu::-webkit-scrollbar-thumb:hover {
    background-color: #7b7b7b; /* Color of the thumb on hover */
}

.dropdown_menu::-webkit-scrollbar-track {
    background-color: #222; /* Color of the track */
}

.dropdown_menu_before {
    content: '';
    position: absolute;
    top: -5px;
    right: 20px;
    height: 20px;
    width: 20px;
    background-color: #c9c9c9;
    transform: translateY(55px) rotate(45deg);
}

.dropdown_menu ul {
    padding: 0;
    margin: 0;
}

.dropdown_menu ul li {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.6);
    list-style-type: none;
    width: 100%;
}

.dropdown_menu ul li:hover{
    background-color: #9c9c9c;
    cursor: pointer;
}

.item_title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.4rem;
}

.item_text {
    font-size: 1rem;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4rem;
}

.item_text_no_wrap {
    font-size: 1rem;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.4rem;
}

.segmented {
    border-top: 1px solid rgba(0, 0, 0, 0.6);
    padding-top: 1rem;
}
.user_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.form_section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.dark_input {
    width: 100%;
    background-color: #555;
    color: white;
    border: none;
    height: 2rem;
}

.button_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;
    gap: 2rem;
}

.error_message {
    color: #ff1f5f;
}
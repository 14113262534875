.row_container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
    min-width: 0;
}

.row_item {
    display: flex;
    padding: 0.2rem 1rem;
    width: 100%;
    height: 2.5rem;
    color: black;
    align-items: center;
    justify-content: space-between;
    background-color: #c9c9c9;
    border: 1px solid rgba(0, 0, 0, 0.6);
}

.row_item_disabled {
    display: flex;
    padding: 0.2rem 1rem;
    width: 100%;
    height: 2.5rem;
    color: black;
    align-items: center;
    justify-content: space-between;
    background-color: #c9c9c9;
    border: 1px solid rgba(0, 0, 0, 0.6);
    opacity: 0.5;
}

.row_item:hover {
    background-color: #a9a9a9;
}

.title_container {
    display: flex;
    gap: 2rem;
    align-items: center;
    width: 90%;
}

.row_item_title {
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    padding: 0;
    margin: 0;
}

.row_item_title_crossed {
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    padding: 0;
    margin: 0;
    text-decoration: line-through;
}

.row_item_subtitle {
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgb(80, 80, 80);
    margin: 0;
}

.prepend_container {
    color: black;
    margin: 0;
}

.append_container {
    color: black;
    margin: 0;
}

.row_children {
    width: 95%;
    align-self: flex-end;
    
}
.scroll_container {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    background-color: #555;
    box-shadow: 0 0 40px 20px inset rgba(0, 0, 0, 0.13);
}

.columns_container {
    width: fit-content;
    height: 'fit-content';
    margin: 0 0.5rem;
}

.outer_container {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 100%;
    padding: 0.5rem;
    gap: 0.5rem;
}

.column_box {
    background-color: #333;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 150px;
    min-width: 150px;
    height: 100%;
    border: 2px solid rgb(0, 0, 0);
}

.title_text {
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
}

.max_input {
    width: 100%;
    background-color: #555;
    color: white;
    border: none;
    height: 2rem;
}

.input_row {
    margin-bottom: 1.5rem;
}

.btn_row {
    display: flex;
    gap: 1rem;
    padding: 0;
    justify-content: space-between;
}

.grouped_btn {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.error_message {
    color: #ff1f5f;
}

.scroll_container::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px;
}

.scroll_container::-webkit-scrollbar-thumb {
    background-color: #b4b4b4; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners of the thumb */
}

.scroll_container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the thumb on hover */
}

.scroll_container::-webkit-scrollbar-track {
    background-color: #222; /* Color of the track */
}

.input_readonly {
    background-color: #181818;
    width: 100%;
    color: white;
    border: none;
    height: 2rem;
    cursor: default;
}

.button_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;
    gap: 2rem;
}

.add_input {
    background-color: #555;
    color: white;
    border: none;
    height: 2rem;
}

.form_section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}
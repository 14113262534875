.outter_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1c1c1c;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner_container {
    position: relative;
    padding: 32px;
    width: 90%;
    max-width: 700px;
    height: 98%;
    max-height: 800px;
    background-color: #333;
    border-width: 5px; 
    border-style: solid;
    border-image: linear-gradient(140deg, rgba(44,246,138,1) 0%, rgba(52,235,186,1) 25%, rgba(52,235,202,1) 67%, rgba(85,248,255,1) 100%) 1;
    border-radius: 10px;
    overflow: auto;
}

.col_style {
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form_section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
    max-width: 500px;
}

.input_line {
    background-color: #555;
    color: white;
    border: none;
    height: 2.5rem;
}

.resend_otp {
    color: #1dffdd;
    font-size: 1rem;
    margin-top: 0.5rem;
    cursor: pointer;
}

.resend_otp:hover {
    color: white;
    text-decoration: none;
}

.form_class {
    margin: 2rem 0;
}
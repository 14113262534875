.btn_container {
    position: relative;
    display: flex;
    padding: 0;
    margin: 0;
    width: inherit;
    height: inherit;
}

.btn {
    background: linear-gradient(140deg, rgba(44,246,138,1) 0%, rgba(52,235,186,1) 25%, rgba(52,235,202,1) 67%, rgba(85,248,255,1) 100%);
    color: #111;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 30px;
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
    cursor: pointer;
    outline: none;
    border: none;
  }

  .btn_disabled {
    background: #969696;
    color: #535353;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 30px;
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
    cursor: not-allowed;
    outline: none;
    border: none;
  }

  .btn_secondary {
    background: transparent;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
    cursor: pointer;
    border: double 4px transparent;
    border-radius: 80px;
    background-image: linear-gradient(#333, #333), linear-gradient(140deg, rgba(44,246,138,1) 0%, rgba(52,235,186,1) 25%, rgba(52,235,202,1) 67%, rgba(85,248,255,1) 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.btn_secondary:hover {
    background-image: linear-gradient(#333, #333), linear-gradient(#23ba92, #23ba92);
    color: rgb(183, 183, 183);
}

.btn_secondary:active {
    background-image: linear-gradient(#333, #333), linear-gradient(#178164, #178164);
    outline: none;
    color: rgb(125, 125, 125);
}

.btn_secondary_warning {
    background: transparent;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
    cursor: pointer;
    border: double 4px transparent;
    border-radius: 80px;
    background-image: linear-gradient(#333, #333), linear-gradient(140deg, rgb(248, 54, 203) 0%, rgb(244, 47, 123)67%, rgb(244, 59, 108) 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.btn_secondary_warning:hover {
    background-image: linear-gradient(#333, #333), linear-gradient(#c52073, #c52073);
    color: rgb(183, 183, 183);
}

.btn_secondary_warning:active {
    background-image: linear-gradient(#333, #333), linear-gradient(#87154e, #87154e);
    outline: none;
    color: rgb(125, 125, 125);
}

.btn_warning {
    background: linear-gradient(140deg, rgb(248, 54, 203) 0%, rgb(244, 47, 123)67%, rgb(244, 59, 108) 100%);
    color: #111;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 30px;
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
    cursor: pointer;
    outline: none;
    border: none;
  }
  
/* Hover state */
.btn:hover {
    background: #23ba92; /* Darker background color on hover */
}

.btn_warning:hover {
    background: #c52073; /* Darker background color on hover */
}

/* Active (clicked) state */
.btn:active {
    background: #178164; /* Even darker background color when clicked */
    outline: none;
}

.btn_warning:active {
    background: #87154e; /* Even darker background color when clicked */
    outline: none;
}

.btn_trnsp {
    background: transparent;
    color: #1dffdd;
    padding: 0;
    font-size: 1rem;
    border: none;
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
    cursor: pointer;
}

/* Hover state */
.btn_trnsp:hover {
    color: #23ba92; /* Darker background color on hover */
}

/* Active (clicked) state */
.btn_trnsp:active {
    outline: none;
    color: #178164; /* Darker background color on hover */
}

.btn_trnsp_disabled {
    background: transparent;
    color: #969696;
    padding: 0;
    font-size: 1rem;
    border: none;
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
    cursor: not-allowed;
}

.btn_warning_trnsp {
    background: transparent;
    color: #ff1f5f;
    padding: 0;
    font-size: 1rem;
    border: none;
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
    cursor: pointer;
}

/* Hover state */
.btn_warning_trnsp:hover {
    color: #c52073; /* Darker background color on hover */
}

/* Active (clicked) state */
.btn_warning_trnsp:active {
    outline: none;
    color: #87154e; /* Darker background color on hover */
}

.gap {
    margin-right: 5px;
}
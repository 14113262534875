.table__container {
  width: 100%;
  background-color: #181818;
  overflow-x: auto;
  padding: 1rem;
}

.table {
    border-collapse: collapse;
}

.table th, .table td {
    flex: 1; /* Make columns equally wide */
    padding: 8px; /* Optional: Add padding for better spacing */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table td {
    text-align: center;
}

.table__container::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px;
}

.table__container::-webkit-scrollbar-thumb {
    background-color: #b4b4b4; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners of the thumb */
}

.table__container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the thumb on hover */
}

.table__container::-webkit-scrollbar-track {
    background-color: #222; /* Color of the track */
}

.selectable_role:hover {
    background-color: #333;
    cursor: pointer;
}

.unselectable_role:hover {
    color: white;
}

.button_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;
    gap: 2rem;
}

.role_input {
    display: flex;
    justify-content: center;
    width: 100%;
}

.error_message {
    color: #ff1f5f;
}

.form_section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.dark_input {
    width: 100%;
    background-color: #555;
    color: white;
    border: none;
    height: 2rem;
}

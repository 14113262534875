.section_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width:50%;
    height: fit-content;
    align-items: center;
    margin: 1rem 0;
}

.project_container {
    height: fit-content;
    padding: 2rem;
    border: 3px solid #181818;
    margin: 1rem;
    box-shadow: 0px 0px 30px 7px rgba(0, 0, 0, 0.5);
}

.project_row {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 100%;
    height: fit-content;
    padding: 0;
    margin: 0;
}

.invite_btn_container {
    width: 100%;
}

.table_title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.project_header {
    width: 100%;
}

.button_row {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.delete_proj_btn {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: flex-end;
    padding: 0;
    margin-top: 4rem;
}

.error_message {
    color: #ff1f5f;
}

.form_section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.dark_input {
    width: 100%;
    background-color: #555;
    color: white;
    border: none;
    height: 2rem;
}

.img_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10rem;
    padding: 0;
}
.page_content {
    margin: 1rem 0;
    padding: 0rem 4rem;
    max-width: 2500px;
}

.page_content_sml {
    margin: 1rem 0;
    padding: 0rem 1rem;
}

.section_container {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width:100%;
    max-width: 80rem;
    height: fit-content;
    padding: 0;
    margin-bottom: 5rem;
}

.sprint_title {
    font-size: 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 0.5rem;
}

.layout_container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 100%;
    height: fit-content;
    padding: 0;
    margin: 0;
}

.ticket_list {
    width: 100%;
    min-width: 0;
}

.ticket_details {
    height: fit-content;
    width: 60%;
    min-width: 30rem;
    padding: 2rem;
    border: 1px solid #e0e0e0;
}

.header_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
}

.long_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 35px;
    line-height: 47px;
}

.epics_box {
    max-width: 80rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #e0e0e0;
}

.labeled_section {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 1rem 0;
}

.dark_input {
    width: 100%;
    background-color: #555;
    color: white;
    border: none;
    height: 2rem;
}

.button_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;
    gap: 2rem;
}

.error_message {
    color: #ff1f5f;
}

.isolated_button {
    margin: 1rem 0;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10rem;
    padding: 0;
}